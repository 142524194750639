<template>
<div class="list_main">
  <div class="list_content">
    <van-index-bar highlight-color="#5288FE">
      <van-index-anchor v-for="(item,index) in List" :key="index" :index="item.title" >
        <span>{{item.title}}</span>
        <van-cell
            @click="close(pItem.area_code)"
            v-for="(pItem,pIndex) in item.list"
            :key="pIndex"
            :title="pItem.region">
          <span>{{pItem.area_code}}</span>
        </van-cell>
      </van-index-anchor>
    </van-index-bar>
  </div>
</div>
</template>

<script>
import {areaCode} from "@/api/public";

export default {
  name: "areaList",
  data() {
    return {
      show: false,
      List: [],
      LEFT: 0
    }
  },
  mounted() {
    this.GetAreaCode()
  },
  methods:{
    close(item){
      this.$emit('AreaCode',item)
    },
    GetAreaCode() {
      areaCode().then(res =>{
        console.log(res)
        this.List = res.data.result
      })
    }
  }
}
</script>

<style scoped lang="scss">
.list_main{
  width: 100%;
  background: #FFFFFF;
  position: absolute;
  left: 0;
  top: 0;
  transition: 1s all ease;
  .list_content{
    width: 100%;
  }
}
</style>
