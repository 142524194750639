<template>
  <div class="login_main">
    <div class="logo">
      <img src="../../assets/images/logo.png" alt="">
    </div>
    <div class="form">
      <div class="form_phone">
        <div class="form_prefix" @click="list">
          <span>{{ prefix }}</span>
          <i class="triangle_border_down"></i>
        </div>
        <input type="text" v-model="phone" placeholder="请输入手机号">
      </div>
      <div class="from_code">
        <input type="text" v-model="code" placeholder="输入验证码">
        <button @click="GetVerifyCode" v-if="send_out">发送验证码</button>
        <span v-else>{{ second }} 秒</span>
      </div>
    </div>
    <button class="login_but" @click="login">确定</button>
    <areaList ref="areaList" v-if="show" @AreaCode="AreaCode($event)"></areaList>
  </div>
</template>

<script>
import areaList from "@/components/areaList";
import _ from 'lodash'
import {verifyCode} from "@/api/user";
import storage from "good-storage";
import {Toast} from "vant";

export default {
  name: "login",
  components: {
    areaList
  },
  data() {
    return {
      phone: '',
      code: '',
      prefix: '+86',
      show: false,
      send_out: true,
      second: 60,
      time: null,
      userSid: ''
    }
  },
  created() {
    let cid = this.$route.query.cid
    if (!cid && !storage.get('cid')) {
      storage.set('cid', '1')
    }
    this.userSid = cid
    console.log(this.userSid)
  },
  methods: {

    // 区号列表
    list() {
      this.show = true
    },
    // 关闭区号列表
    AreaCode(data) {
      this.prefix = data
      this.show = false
    },
    // 验证手机号格式
    checkPhone(phone) {
      if (this.prefix === '+86') {
        const registered_phone = /^1([358][0-9]|4[579]|66|7[0135678]|9[89])[0-9]{8}$/
        if (registered_phone.test(phone) === true && phone !== '') {
          return phone
        } else {
          Toast({
            message: '请输入有效的手机号码',
            icon: 'fail',
          });
          throw '手机号码有误'
        }
      } else {
        return phone
      }
    },
    // 验证码校验
    checkCode(code) {
      if (code.length === 4 && code !== '') {
        return code
      } else {
        Toast({
          message: '验证码错误，请重新输入',
          icon: 'fail',
        });
        throw '验证码有误'
      }
    },
    // 获取验证码
    GetVerifyCode: _.debounce(function () {
      const info = {
        prefix: this.prefix,
        phoneNumber: this.checkPhone(this.phone),
        send_location: 'empower_verifyCode'
      }
      verifyCode(info).then(res => {
        this.SendOut()
        console.log(res)
      })
    }, 500),
    // 进入倒计时
    SendOut() {
      this.send_out = false
      this.time = setInterval(() => {
        if (this.second > 0) {
          this.second -= 1;
        } else if (this.second === 0) {
          this.send_out = true
          clearInterval(this.time)
        }
      }, 1000)
    },
    // 登录方法
    login() {
      const s = storage.get('s') || ''
      const info = {
        phone: this.checkPhone(this.phone),
        prefix: this.prefix,
        verifyCode: this.checkCode(this.code),
        id: this.userSid,
        s: s,
        send_location: 'empower_verifyCode',
      }
      this.$store.dispatch('user/login', info).then(res => {
        if (res.auth_status !== '2'){
          this.$router.push({path: `/authentication`})
        }else {
          this.$router.push({path: '/'})
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.login_main {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  .logo {
    margin-bottom: 77px;
  }

  .form {
    margin-bottom: 59px;

    .form_phone {
      width: 280px;
      border-bottom: 1px solid #DDDDDD;
      display: flex;
      flex-flow: row;
      justify-content: flex-start;
      align-items: center;
      box-sizing: content-box;
      padding: 0 4px;
      margin-bottom: 24px;

      .form_prefix {
        width: 50px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
        display: flex;
        flex-flow: row;
        justify-content: flex-start;
        align-items: center;
        .triangle_border_down {
          display: inline-block;
          width: 8px;
          height: 8px;
          background-image: url("../../assets/images/triangle_border_down.svg");
          background-repeat: no-repeat;
          margin: 0 5px;
        }
      }



      input {
        border: none;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }
    }

    .from_code {
      width: 280px;
      border-bottom: 1px solid #DDDDDD;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: content-box;
      padding: 0 4px;

      input {
        width: 120px;
        border: none;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }

      & > button {
        border: none;
        background: #FFFFFF;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #1A6DC4;
        line-height: 20px;
      }

      span {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }
    }
  }

  .login_but {
    border: none;
    width: 280px;
    height: 44px;
    background: #1A6DC4;
    border-radius: 4px;
    font-size: 17px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 20px;
  }
}
</style>
