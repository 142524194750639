import request from '@/utils/request'

// 获取区号
export function areaCode(params) {
    return request({
        url: 'api/area_code',
        method: 'GET',
        params
    })
}
